<!-- 招生系统基础配置 -- 中考考试科目管理 -- 列表 -->
<template>
  <div>
    <list-template
        :loading="loading"
        :search-config="searchConfig"
        @onSearch="onSearch"
        :table-config="tableConfig"
        :table-data="tableData"
        :total="total"
        @onAdd="$router.push('./add')"
        :current-page="page"
        @onChangePage="handleCurrentChange"
        @onHandle="handleEdit"
        @onReset='reset'
    ></list-template>

    <!-- 历史记录 -->
    <h-dialog :historyDialogShow="historyDialogShow" :historyList="historyList" @close="historyDialogShow = false"></h-dialog>
  </div>
</template>

<script>
//引入ajax接口管理层的接口函数
import {mapState} from "vuex";

export default {
	_config:{"route":{"path":"list","meta":{"title":"列表","keepAlive":true}}},
  data() {
    return {
      loading:true,
      search:{province: '',city: '',area: ''},
      searchConfig:[
        {
          prop:"province",
          tag: 'select',
          placeholder:"查询省份",
          label: 'address_name',
          value: 'address_code',
          options: [],
          change: (e,s) => {
            this.searchConfig[1].options = [];
            this.searchConfig[2].options = [];
            s.city = '';
            s.area = '';
            this.getCity(e)
          }
        },
        {
          prop:"city",
          tag: 'select',
          placeholder:"查询城市",
          label: 'address_name',
          value: 'address_code',
          options: [],
          change: (e,s) => {
            this.searchConfig[2].options = [];
            s.area = '';
            this.getArea(e)
          }
        },
        {
          prop:"area",
          tag: 'select',
          placeholder:"查询区/县",
          label: 'address_name',
          value: 'address_code',
          options: [],
        },
      ],
      tableData: [],
      //总条数
      total: 0,
      pageCount: 0,

      tableConfig:[
        {prop:"province", label:"省份"},
        {prop:"city", label:"城市"},
        {prop:"area", label:"区/县"},
        {prop:"total_score",label:"总分"},
        {prop:"created_at", label:"创建时间"},
        {prop:"creator", label:"创建人"},
        {
          prop:"handle",
          label:"操作",
          width: "180rem",
          handle: true,
          render() {
            return ["编辑", "查看历史记录"]
          }
        },
      ],

      historyDialogShow: false,
      historyList: []
    }
  },
  created() {
    this.$store.commit("setPage",1);
    this.getProvince()
    this.getData()
  },
  activated() {
    this.getData()
  },
  computed:{
    ...mapState(["page"])
  },
  methods: {
    getData(){
      let params = {...this.search, page: this.page};
      this.loading = true;
      this.$_register('/api/recruit/examination',{params}).then(res => {
        let data = res.data.data
        this.tableData = data.list
        this.total = data.page.total
      }).finally(()=>this.loading = false);
    },

    onSearch(val){
      this.$store.commit('setPage', 1);
      this.search = val;
      this.getData(val)
    },

    getProvince(){
      this.$_register('/api/recruit/common/area',{params:{address_code: '000000'}}).then(res => {
        this.searchConfig[0].options = res.data.data
      })
    },

    getCity(e){
      this.$_register('/api/recruit/common/area',{params:{address_code: e}}).then(res => {
        this.searchConfig[1].options = res.data.data
      })
    },

    getArea(e){
      this.$_register('/api/recruit/common/area',{params:{address_code: e}}).then(res => {
        this.searchConfig[2].options = res.data.data
      })
    },

    handleEdit(row, text) {
      console.log(row, text)
      if (text.type === 'edit') {
        this.$router.push('./edit?id=' + row.id)
      }

      if (text.type === 'viewHistory') {
        let params = {
          tbl_name: 'examination',
          tbl_id: row.id
        }
        this.$_register.get('/api/recruit/common/get-opt-history',{params}).then(res => {
          let data = res.data.data
          this.historyList = data.list.length ? data.list : []
          this.historyDialogShow = true;
        })
      }
    },

    // 当前页改变触发
    handleCurrentChange(val) {
      //当前页赋值给currentPage
      this.$store.commit("setPage",val);
      //拉取数据
      this.getData()
    },

    reset(){
      // Object.keys(this.search).forEach(item => {
      //   this.search[item] = ''
      // })
      this.searchConfig[1].options = []
      this.searchConfig[2].options = []
    }

  },
}
</script>

<style lang="scss" scoped>
.role-list {
  padding-top: 24rem;
}
.button {
  // height: 100rem;
  //  padding-top: 30rem;
  margin-left: 34rem;
  margin-bottom: 30rem;
  box-sizing: border-box;
  width: 90rem;
  height: 34rem;
  //background: rgba(24, 24, 120);
  opacity: 1;
  border-radius: 4rem;
  // display: flex;
  // .button-item {
  // width: 120rem;
  // font-size: 25rem;

  // display: flex;
  // justify-content: space-between;
  // align-items: center;
  // .additem{
  //   // font-size:20rem;
  //   display: flex;
  //   // justify-content: space-between;
  //   align-items: center;
  //   padding-left:3rem;
  //   //  word-spacing:10rem;
  //   .item{
  //     margin-left:10rem;
  //   }
  // }
}
// }
.table {
  margin-left: 32rem;
}


::v-deep .el-table--enable-row-transition .el-table__body td.el-table__cell {
  text-align: left;
  font-size: 14rem;
  font-family: Microsoft YaHei;
  font-weight: normal;
  height: 48rem;
  box-sizing: border-box;
  color: #333333;
}
.header {
  position: fixed;
  top: 120rem;
  left: 300rem;
  font-size: 16rem;
  font-family: Microsoft YaHei;
  font-weight: bold;
  line-height: 24rem;
  color: #333333;
  letter-spacing: 3.5rem;
}
.el-button--text {
  margin-right: 40rem;
  font-size: 14rem;
  font-family: Microsoft YaHei;
  font-weight: normal;
  color: #3491fa;
}
</style>
